import { css } from "theme-ui";

let rem = (px, base = 16) => `${px / base}rem`;

export let cv = {
  container: css({
    marginBottom: "16px",
  }),
};

export let workLink = css({
  marginTop: "15px",
  marginBottom: "8px",
  paddingBottom: `${rem(5)}`,
  display: "inline-block",
  color: "link",
  textTransform: "uppercase",
  position: "relative",
  fontWeight: "bold",
  transition: "color ease 0.3s",
  "&:hover": {
    color: "linkHover",
  },
  svg: {
    transition: "stroke-opacity ease 0.3s",
  },
});

export let skillItem = css({
  color: "grey",
  fontSize: 3,
  marginRight: [rem(8), rem(8), 0],
  marginTop: rem(8),
  fontWeight: "bold",
});

export let sectionTitle = css({
  color: "grey",
  marginY: rem(10),
  fontWeight: "bold",
  fontSize: 4,
  fontFamily: "heading"
});
