import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "theme-ui";

import theme from "styles/theme";
import "./index.css";
import "./styles/type.css";

let root = document.getElementById("root");
let ENV = process.env.NODE_ENV;

let EntryPoint = () => (
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

if (root.hasChildNodes() && ENV === "production") {
  ReactDOM.hydrate(<EntryPoint />, root);
} else {
  ReactDOM.render(<EntryPoint />, root);
}

// Runtime linter for checking accessability
if (ENV === "development") {
  let axe = require("react-axe");
  axe(React, ReactDOM, 400);
}
